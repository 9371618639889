import React, { lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import BaseLayout from 'src/layouts/BaseLayout';
import SuspenseLoader from 'src/components/SuspenseLoader';
import { routes as routesV2, routesMirrorsAssignNotFound } from './router_v2';
import { UserRoles } from './utils/constants';
import { AuthUser } from './containers_v2/auth/store/slice';
import config from './config';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);
const QRcodeDynamic = Loader(
  lazy(() => import('src/content/pages/QRcodeDynamic'))
);

const ForgotPassword = Loader(
  lazy(() => import('src/containers_v2/auth/forgot-password'))
);

const ResetPassword = Loader(
  lazy(() => import('src/containers_v2/auth/forgot-password/reset-password'))
);

const ExpiredQRCode = Loader(
  lazy(() => import('./content/pages/ExpiredQRCode/index'))
);

const LoginPage = Loader(lazy(() => import('src/containers_v2/auth/login')));

const UserHelpDesk = Loader(
  lazy(() => import('src/containers_v2/helpdesk/UserHelpDesk'))
);

const AdminHelpDesk = Loader(
  lazy(() => import('src/containers_v2/helpdesk/AdminHelpDesk'))
);

const NotFound8thwall = Loader(
  lazy(() => import('./content/pages/Status/NotFound8thwall'))
);

const Raffle = Loader(lazy(() => import('src/containers_v2/auth/raffle')));

export const unAuthRoutes = [
  {
    path: '/',
    element: <Navigate to="/login" replace />
  },
  {
    path: '/login',
    element: <LoginPage />
  },
  {
    path: 'forgot-password',
    element: <ForgotPassword />
  },
  {
    path: 'reset-password',
    element: <ResetPassword />
  },
  {
    path: 'qrcode',
    element: <QRcodeDynamic />
  },
  {
    path: 'expired-qrcode',
    element: <ExpiredQRCode />
  },
  {
    path: 'helpdesk',
    element: <UserHelpDesk />
  },
  {
    path: 'admin/helpdesk',
    element: <AdminHelpDesk />
  },
  {
    path: '8thwall/404',
    element: <NotFound8thwall />
  },
  {
    path: '/raffle/:qrId/:mirrorId/:age/:days/:trackingId',
    element: <Raffle />
  },
  {
    path: '*',
    element: <Navigate to="/login" replace />
  }
];
export const authRoutes = (authUser: AuthUser) => {
  const isMirrorOwner = authUser.role.includes(
    UserRoles.toString(UserRoles.Mirrors_owner)
  );
  const isMirrorViewer = authUser.role.includes(
    UserRoles.toString(UserRoles.Mirror_viewer)
  );
  const isAdmin = authUser.role.includes(UserRoles.toString(UserRoles.Admin));

  let routes = [
    {
      path: '/',
      element: <Navigate to="/reports" replace />
    },
    {
      path: '/login',
      element: <Navigate to="/reports" replace />
    },
    {
      path: '/forgot-password',
      element: <Navigate to="/reports" replace />
    },
    {
      path: '/reset-password',
      element: <Navigate to="/reports" replace />
    },
    {
      path: 'qrcode',
      element: <QRcodeDynamic />
    },
    {
      path: 'helpdesk',
      element: <UserHelpDesk />
    },
    {
      path: 'admin/helpdesk',
      element: <AdminHelpDesk />
    },
    {
      path: '/raffle/:qrId/:mirrorId/:age/:days/:trackingId',
      element: <Raffle />
    },
    {
      path: '*',
      element: <BaseLayout />,
      children: [
        {
          path: '/',
          element: <Navigate to="/reports" replace />
        },
        {
          path: 'status',
          children: [
            {
              path: '/',
              element: <Navigate to="404" replace />
            },
            {
              path: '404',
              element: <Status404 />
            },
            {
              path: '500',
              element: <Status500 />
            },
            {
              path: 'maintenance',
              element: <StatusMaintenance />
            },
            {
              path: 'coming-soon',
              element: <StatusComingSoon />
            }
          ]
        },
        {
          path: '8thwall/404',
          element: <NotFound8thwall />
        },
        {
          path: '*',
          element: <Status404 />
        }
      ]
    }
  ];

  if (
    !isAdmin &&
    (isMirrorOwner || isMirrorViewer) &&
    authUser.mirrorIds?.length === 0
  ) {
    routes.push({ ...routesMirrorsAssignNotFound });
    return routes;
  }

  if (authUser.isArProjectCreationExpired) {
    routesV2.children = routesV2.children.filter(
      (item) =>
        item.path !== 'ar-namecard/postcontent' &&
        item.path !== 'ar-pamphlet/postcontent'
    );
  }

  routes.push({ ...routesV2 });
  return routes;
};
