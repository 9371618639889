import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { ProfileType } from 'src/containers_v2/mirror-configuration/profile/profile-list/store/slice';

export type ContentConfigurationType = {
  id: number;
  value: string;
  category: number,
  category2: number,
  category3: number,
  category4: number,
  childNodes?: ContentConfigurationType[]
};

export type MirrorTypeConfigurationType = {
  id: number;
  type: string;
  content: ContentConfigurationType[];
  profiles?: ProfileType[];
};

export interface MirrorTypeConfigurationState {
  configMirrorType: MirrorTypeConfigurationType;
  loading: boolean;
  error: string;
  saved: boolean;
}

const initialState: MirrorTypeConfigurationState = {
  configMirrorType: undefined,
  error: '',
  loading: false,
  saved: false
};

const mirrorTypeConfigSlice = createSlice({
  name: 'mirrorTypeConfiguration',
  initialState,
  reducers: {
    resetState: () => initialState,
    saveMirrorType(
      state: MirrorTypeConfigurationState,
      action: PayloadAction<MirrorTypeConfigurationType>
    ) {
      state.loading = true;
      state.error = '';
    },
    mirrorTypeSaved(state: MirrorTypeConfigurationState) {
      state.loading = false;
      state.saved = true;
    },
    mirrorTypeSavedError(
      state: MirrorTypeConfigurationState,
      action: PayloadAction<string>
    ) {
      state.loading = false;
      state.error = action.payload;
    },
    fetchMirrorType(
      state: MirrorTypeConfigurationState,
      action: PayloadAction<string>
    ) {
      state.loading = true;
    },
    mirrorTypeFetched(
      state: MirrorTypeConfigurationState,
      action: PayloadAction<MirrorTypeConfigurationType>
    ) {
      state.loading = false;
      state.configMirrorType = action.payload;
    },
    mirrorTypeFetchedError(state: MirrorTypeConfigurationState) {
      state.loading = false;
      state.error = 'Fail to load mirror type';
    }
  }
});

export default mirrorTypeConfigSlice.reducer;
export const mirrorTypeConfigActions = mirrorTypeConfigSlice.actions;
