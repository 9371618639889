import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import authApi from 'src/api_v2/auth/auth-api';
import raffleApi from 'src/api_v2/raffle/raffle-api';
import { authActions, LoginParams, QrDataParams } from './slice';
import jwt_decode from 'jwt-decode';
import { setAuth, setUser } from 'src/redux/store/user/slice';
import { selectAuthUser } from './selector';
import groupListApi from 'src/api_v2/group/group-list-api';
import moment from 'moment';

function* login(action: PayloadAction<LoginParams>) {
  try {
    const result = yield call(
      authApi.login,
      action.payload.email,
      action.payload.password
      );

    if (result.access_token) {
      localStorage.setItem('token', result.access_token);
      localStorage.setItem('username', action.payload.email.split('@')[0])
      const decoded: any = jwt_decode(result.access_token);
      yield put(
        authActions.loginSuccess({
          username: decoded.uuid,
          email: action.payload.email,
          role: decoded.role,
          id: decoded.id,
          groupId: decoded.groupId,
          mirrorIds: result.mirrorIds,
        })
      );
      yield put(
        setUser({
          id: decoded.id,
          uuid: decoded.uuid,
          role: decoded.role
        })
      );
      yield put(setAuth({ isAuth: true }));
    } else {
      yield put(authActions.loginError());
    }
  } catch (err) {
    yield put(authActions.loginError());
  }
}

function* checkUserArProjectCreationExpired() {
  try {
    const authUser = yield select(selectAuthUser)

    const result = yield call(groupListApi.getGroupByUser, authUser.id)

    if(result) {
      const createExpirationDate = result.createExpirationDate

      if(moment().isAfter(moment(createExpirationDate))) {
        yield put(authActions.userArProjectCreationExpiredChecked(true))
      }
    }
  } catch (err) {}
}


function* postQrData(action: PayloadAction<QrDataParams>) {
  try {
    const result = yield call(
      raffleApi.postQrData,
      action.payload.qrId,
      action.payload.mirrorId,
      action.payload.deviceInfo,
      action.payload.isEnable,
      action.payload.days,
      action.payload.trackingId,
    );

    yield put(authActions.qrDataRes(result))

  } catch (err) {
    yield put(authActions.qrDataResError());
  }
}

export default function* watchAuthSaga() {
  yield takeLatest(authActions.login.type, login);
  yield takeLatest(authActions.checkUserArProjectCreationExpired.type, checkUserArProjectCreationExpired);
  yield takeEvery(authActions.postQrData.type, postQrData);
}
